<template>
    <div class="flex flex-item flex-column">
        <ts-table-operate :name="nameKey" :table-name="tableName" v-if="cols.length > 0" :cols="cols"
            @handleFilter="handleFilter" @searchShowLeft="searchShowLeft" @handleSort="sort"
            @viewChange="viewChange"></ts-table-operate>
        <ts-table ref="table" class="flex-item" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
            @handleSort="sort" :error="pageParam.error" :error-code="error" :name="nameKey"
            @handleRowDbClickChange="handleRowDbClickChange" full @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
                    @searchClose="searchClose" @handleFilter="handleFilter" :isListOutQueryView="false"></ts-search-left>
            </template>
        </ts-table>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
import searchMixin from './searchMixin'
export default {
    mixins: [myMixin, searchMixin],
    props: {
        baseQuery: {
            default: () => ({})
        },
        visitId: {},
    },
    data() {
        return {
            config: {
                key: 'base_article_guide'
            },
            nameKey: 'assistPoint',
            tableName: 'base_article_guide',
            pageApi: 'instruction_index/article-guide',
            cols: []
        }
    },
    methods: {
    }
}
</script>